#carousel-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;

  svg {
    width: 48px;
    height: 48px;
    fill: #1c2237;
    margin: 1em;
    cursor: pointer;
  }

  .carousel {
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    overflow-x: auto;
    padding: 1em 0;

    &::-webkit-scrollbar {
      display: none;
    }

    .carousel-item {
      scroll-snap-align: center;
      position: relative;
    }
  }
}

@media screen and (max-width: 800px) {
  #carousel-wrapper {
    .carousel {
      .carousel-item {
        min-width: 320px;
        padding: 2em;
        margin: 0 1em;

        .cardview {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}
