.input {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    color: rgba(28, 34, 55, 0.4);
  }

  &.error {
    input {
      border: 2px solid #ef5350;
      color: #ef5350;
    }
  }

  input {
    flex: 1;
    background: #f1f3f6;
    outline: none;
    border: 2px solid #f1f3f6;
    width: 100%;
    margin-bottom: 16px;
    padding: 8px;
    border-radius: 4px;
    color: #1c2237;
    font-size: 16px;

    &:active {
      border-bottom: 2px solid #1c2237;
    }

    &:focus {
      border-bottom: 2px solid #1c2237;
    }
  }
}
