@import "../../App.scss";

.cardview {
  padding: 16px;
  margin: 8px;
  width: 320px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);

  .cardview-container {
    display: flex;
    flex-direction: column;
    color: #1c2237;

    .cardview-image-wrapper {
      text-align: center;

      .cardview-image {
        height: 100px;
        border-radius: 5px;
        margin: 1em 0;
      }
    }

    .cardview-topline {
      color: #f1c03a;
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      margin-bottom: 16px;
    }

    .cardview-headline {
      display: flex;
      align-items: center;
      font-size: 20px;
      margin-bottom: 8px;

      svg {
        width: 20px !important;
        height: 20px !important;
        fill: $secondary-color;
      }
    }

    .cardview-rating {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin: 12px auto;

      svg {
        font-size: 32px;
        color: #fdd835;
      }
    }
  }
}
