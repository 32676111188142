/* Global Configuration File */

/* Colors */
$primary-color: #1c2237;
$secondary-color: #494e66;
$accent-color: #f1c03a;
$white: #fafafaee;

/* Font Family */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Font styling */

h1 {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
}

.light {
  color: $white;
}

.dark {
  color: $primary-color;
}

.center {
  text-align: center;
}

.justify {
  text-align: justify;
}

a {
  text-decoration: none;
  color: $secondary-color;
}

/* Margins and Paddings */
.mt {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 16px;
}

.pt-80 {
  padding-top: 80px;
}

.mb {
  margin-bottom: 0.5em;
}

.mb-2 {
  margin-bottom: 1em;
}

.mb-4 {
  margin-bottom: 2em;
}

ul {
  margin-left: 2em;
}

@media only screen and (max-width: 576px) {
  h1 {
    font-size: 32px;
  }
}
