@import "../../App.scss";

.checkbox {
  display: flex;
  align-items: center;
  width: 100%;

  input {
    height: 20px;
    width: 20px;
    margin: 0 8px 0 0;
  }

  label {
    color: #1c2237;
  }
}

#confirm {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid $primary-color;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: $white;
  cursor: pointer;
}

#confirm:hover {
  background-color: #f1f3f6;
}

#confirm:checked {
  border: 1px solid $white;
  background-color: $primary-color;
}

@media only screen and (max-width: 576px) {
  .checkbox {
    input {
      width: 32px;
    }
  }
}
