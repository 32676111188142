@import "../../App.scss";

#jens .hero-text-wrapper{
  padding-bottom: 0px;
  padding-top: 45px;
}

#jonas .hero-text-wrapper{
  padding-bottom: 0px;
  padding-top: 45px;
}