@import "../../App.scss";

.hero-section {
  color: $white;
  padding: 160px 0;
}

.hero-row {
  align-items: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.hero-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  color: $accent-color;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.darkBg {
  background-color: $primary-color;
}

.hero-subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
  hyphens: auto;
}

.hero-img-wrapper {
  max-width: 555px;
}

.hero-img {
  max-width: 95%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media (max-width: 960px) {
  .hero-section {
    color: #fff;
    padding: 80px 0;
  }

  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .hero-text-wrapper {
    padding-bottom: 65px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}
