@import "../../App.scss";

.service-section {
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f1f3f6;
}

.service-container-card {
  background: #1c2237;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 280px;
  height: 500px;
  text-decoration: none;
  border-radius: 4px;
  transition: 0.3s all ease-in-out;

  &:nth-child(2) {
    margin: 0 24px;
  }

  &:hover {
    transform: scale(1.05);
    color: #1c2237;
  }

  .icon svg {
    height: 60px;
    width: 60px;
  }
}

.service-container {
  display: flex;
  justify-content: center;
  align-content: center;
}

.service-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-heading {
  text-align: center;
  font-size: 48px;
  color: #1c2237;
  margin-bottom: 24px;
}

.service-container-card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
  padding: 24px;
  color: #fff;

  .icon {
    margin: 24px 0;
  }

  h4 {
    font-size: 40px;
    color: $accent-color;
  }

  p {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .btn {
    border-color: $accent-color;
    color: $accent-color;
  }
}

.service-container-features {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 16px 0 32px;
  list-style: none;

  li {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 960px) {
  .service-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .service-container-card {
    width: 90%;
  }

  .service-wrapper {
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .service-container-card {
    &:nth-child(2) {
      margin: 24px;
    }
    &:hover {
      transform: none;
    }
  }
}
