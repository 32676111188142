@import "../../App.scss";

.contact_postal_address{
    width: 100 vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    color: $primary-color;
}

.contact_postal_address h2{
    padding-bottom: 20px;
}

.contact_postal_address p{
    line-height: 25px;
}

.contact_postal_address span{
    font-weight: bold;
}