@import "../../App.scss";

.projects-section {
  padding: 100px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f1f3f6;

  .projects-heading {
    text-align: center;
    font-size: 48px;
    color: #1c2237;
    margin-bottom: 24px;
  }

  .projects-container {
    display: flex;
    justify-content: center;
  }
}
