@import "../../App.scss";

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  background-color: $primary-color;
  color: $white;
  font-weight: bold;
  border: 2px solid $primary-color;

  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  /* Styles */

  &.outline {
    background-color: transparent;
    color: $white;
    border: 2px solid $white;
    transition: all 0.3s ease-out;
  }

  /* Sizes */

  &.medium {
    font-size: 18px;
  }

  &.large {
    padding: 12px 26px;
    font-size: 20px;
  }

  &.wide {
    padding: 12px 64px;
    font-size: 20px;
  }

  &:enabled:hover {
    transition: all 0.3s ease-out;
    background: $white;
    color: $primary-color;
  }

  &:enabled.wide:hover {
    color: $primary-color;
    background-color: $white;
    border: 2px solid $primary-color;
    transition: all 0.3s ease-out;
  }

  /* Colors */

  &.inverted {
    color: $white;
    border: 2px solid $white;
  }

  &.inverted:hover {
    color: $primary-color;
    border: 2px solid $white;
    background-color: $white;
  }
}

@media (max-width: 960px) {
  .btn {
    text-align: center;
    text-decoration: none;
    font-size: 24px;
    background-color: transparent;
    color: $primary-color;
    padding: 14px 20px;
    border: 2px solid $primary-color;
    transition: all 0.3s ease-out;
  }
}
