.contact-section {
  margin: 0 auto;
  max-width: 1300px;
  padding: 40px 0;

  .contact-wrapper {
    color: #fff;
    padding: 40px 0;

    .row {
      display: flex;
      flex-wrap: wrap;
      align-content: stretch;

      .col {
        margin-bottom: 15px;
        padding-right: 15px;
        padding-left: 15px;
        flex: 1;
        max-width: 50%;
        flex-basis: 50%;

        .name2 {
          display: flex;
          gap: 16px;
          color: black;
          div {
            display: flex;
            flex-direction: column;
            flex: 1;
            background-color: blue;

            input {
              background: #f1f3f6;
              outline: none;
              border: 2px solid #f1f3f6;
              margin-bottom: 16px;
              padding: 8px;
              border-radius: 4px;
              color: #1c2237;
              font-size: 16px;
            }
          }
        }

        .name {
          display: flex;
          gap: 16px;

          .test {
            flex: 1;
            background-color: blue;
          }

          .input {
            flex: 1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-section {
    .contact-wrapper {
      .row {
        .col {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
  }
}
